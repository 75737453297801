export const usePhoneNumber = (phoneNumber:string, desiredFormat:'flat'|'dot') => {
  const phoneRegex = /(?:\+33\s?|\(0\)\s?|\+33\(0\)\s?)?0?(\d{1})\s?[\s.-]?(\d{2})[\s.-]?(\d{2})[\s.-]?(\d{2})[\s.-]?(\d{2})/;
  const match = phoneNumber ? phoneNumber.match(phoneRegex) : null;

  if (match) {
    switch(desiredFormat) {
      case('flat'):
        return `0${match[1]}${match[2]}${match[3]}${match[4]}${match[5]}`;
      case('dot'):
        return `0${match[1]}.${match[2]}.${match[3]}.${match[4]}.${match[5]}`;

    }
  }
      return 'Numéro de téléphone invalide';
}
